<div class="row">
	<div class="col-md-12">
		<app-loading *ngIf="loading" class="loading-container" [bigSize]="true"> </app-loading>
	</div>
</div>
<div class="row mb-2">
	<div class="col-sm-4 d-flex justify-content-center justify-content-sm-start">
		<button class="btn btn-outline-primary text-nowrap" [routerLink]="'/dashboard'">
			<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
			Back to Dashboard
		</button>
	</div>
	<div class="col-sm-4 text-center">
		<div class="stats-main-number font-weight-bold">Messages</div>
	</div>
	<div class="col-sm-4 d-flex justify-content-center justify-content-sm-end">
		<button class="btn btn-outline-primary float-md-right float-sm-none" (click)="createMessage()">
			<span>
				<fa-icon [icon]="['fas', 'envelope']"></fa-icon>
			</span> New Message
		</button>
	</div>
</div>
<div class="card overflow-auto">
	<div class="card-body">
		<mat-accordion>
			<cdk-virtual-scroll-viewport itemSize="50"
				(scrolledIndexChange)="scrollHandler($event,(messages.Items[messages.Items.length-1]))">
				<mat-expansion-panel *cdkVirtualFor="let item of messages.Items;" class="my-1 ml-n4">
					<mat-expansion-panel-header class="my-1" (click)="setItemsExpanded(item.id)">
						<mat-panel-title *ngIf="item.title.mode === 'Email'">
							<div class="container">
								<div class="row">
									<div class="col-lg-3 font-weight-bold text-primary">
										{{item.title.dateSent}}
									</div>
									<div class="col-lg-2 font-weight-bold text-primary">
										Members
									</div>
									<div class="col-lg font-weight-bold text-primary">
										Subject
									</div>
								</div>
								<div class="row">
									<div class="col-lg-3">
										{{item.title.mode}}
									</div>
									<div class="col-lg-2">
										{{item.title.count}}
									</div>
									<div class="col-lg " [ngClass]="{'text-truncate':!item.expanded}">
										{{item.title.subject}}
									</div>
								</div>
							</div>
						</mat-panel-title>
						<mat-panel-title *ngIf="item.title.mode === 'SMS'">
							<div class="container">
								<div class="row">
									<div class="col-lg-3 font-weight-bold text-primary">
										{{item.title.dateSent}}
									</div>
									<div class="col-lg-2 font-weight-bold text-primary">
										Members
									</div>
								</div>
								<div class="row">
									<div class="col-lg-3">
										{{item.title.mode}}
									</div>
									<div class="col-lg-2">
										{{item.title.count}}
									</div>
								</div>
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="container">
						<div class="row">
							<div class="col-md-12 font-weight-bold text-primary">
								Members:
							</div>
							<div class="col-md-12 card-body">
								{{item.expandedContent.members}}
							</div>
							<div class="col-md-12 font-weight-bold text-primary">
								Body:
							</div>
							<div class="col-md-12 card-body border ml-3">
								{{item.expandedContent.body}}
							</div>
						</div>
					</div>
				</mat-expansion-panel>
			</cdk-virtual-scroll-viewport>
		</mat-accordion>
	</div>
</div>