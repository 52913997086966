import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@app/services/notification.service';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AppCookieService } from '@app/services/app-cookie.service';

import { sep } from 'node:path';
import { getLocaleDayNames } from '@angular/common';
import { MessageBundle } from '@angular/compiler';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {

    @ViewChild(CdkVirtualScrollViewport)
    viewport: CdkVirtualScrollViewport;

    theEnd = false;

    messages = {
        "Items": [],
        "Count": null,
        "ScannedCount": null
    };
    loading = true;
    searching = true;
    LastEvaluatedKey = null;
    lastEvalArr = [];
    collectionSize: number;
    page = 0;
    defaultItemsPerPage = 10;
    expandedMessage = null;
    defaultMessageSearchModel = {
        "employer": "Global_Steering",
        "itemsPerPage": 10
    };
    searchModel = {};


    constructor(private service: NotificationService, private router: Router, private cookie: AppCookieService) { }

    ngOnInit(): void {
        this.loadMessages();
    }

    loadMessages() {
        let search = this.defaultMessageSearchModel;
        this.searchMessages(search);

        this.collectionSize = this.messages.Items.length;
    }

    setItemsExpanded(id) {
        this.messages.Items.forEach(item => {
            let temp = item.expanded;
            item.expanded = item.id === id;
            if (temp == item.expanded)
                item.expanded = false;
        });
    }

    formatMessages() {
        for (let m = 0; m < this.messages.Items.length; m++) {
            this.messages.Items[m]["id"] = m;
            this.messages.Items[m]["title"] = this.getTitleParts(this.messages.Items[m]);
            this.messages.Items[m]["expanded"] = false;
            this.messages.Items[m]["expandedContent"] = this.getMessageContent(this.messages.Items[m]);
        }
    }

    getMessageContent(message) {
        let content = { "members": null, "body": null };
        content.members = this.getRecipientNames(message);
        content.body = message.content;
        return content;
    }

    getTitleParts(message) {
        let title = { "dateSent": null, "mode": null, "count": null, "subject": null }
        title.dateSent = this.formatDate(message.skey.split('#')[1]);
        title.mode = this.getMessageMode(message.skey);
        title.count = Number(message.count).toLocaleString();
        title.subject = message.subject;
        return title;
    };

    formatDate(dateStr) {
        let date = dateStr.split('T')[0];
        let parts = date.split('-');
        const separator = "/";
        return parts[1] + separator + parts[2] + separator + parts[0];
    }

    getMessageMode(skey) {
        let method = skey.split('#')[2];
        if (method === "E") {
            return "Email";
        } else {
            return "SMS";
        }
    }

    getRecipientNames(message) {
        let names = "";
        message.members.split(',').forEach(mem => {
            let parts = mem.split('#');
            let name = this.makeTitleCase(parts[0]) + " " + this.makeTitleCase(parts[1]);
            names += name + ", ";
        });
        names = names.substring(0, names.length - 2);
        return names;
    }

    makeTitleCase(name: string) {
        name = name.toLowerCase();
        name = name.charAt(0).toUpperCase() + name.slice(1)
        return name;
    }

    createMessage() {
        this.router.navigateByUrl('send-messages');
    }

    ngOnDestroy(): void {
    }

    expand(id: number) {
        if (id === this.expandedMessage)
            id = null;

        for (let m = 0; m < this.messages.Items.length; m++) {
            if (this.messages.Items[m].id === id || this.messages.Items[m].id === this.expandedMessage)
                this.messages.Items[m].expanded = !this.messages.Items[m].expanded;

        }

        this.expandedMessage = id;
    }

    getType(type: String) {
        return type === 'E' ? "Email" : "SMS";
    }

    onSearch() {
        this.searchModel = this.defaultMessageSearchModel;
        this.searchModel["lastEvaluatedKey"] = this.LastEvaluatedKey;
        this.searchMessages(this.searchModel);
    }

    reinitializeVariables() {
        this.page = 0;
        this.collectionSize = 0;
        this.lastEvalArr = [];
        this.LastEvaluatedKey = {};
    }

    private searchMessages(search) {
        this.searching = true;
        this.service.searchBulkNotifications(search).subscribe(response => {
            response.Items.forEach(message => {
                this.messages.Items = [...this.messages.Items, message];
            });
            this.formatMessages();
            this.page = this.page++;
            if (response.LastEvaluatedKey && this.keyNotAlreadyContained(response.LastEvaluatedKey)) {
                this.lastEvalArr.push(response.LastEvaluatedKey);
                this.searchModel["lastEvaluatedKey"] = response.LastEvaluatedKey;
                this.LastEvaluatedKey = response.LastEvaluatedKey;
            }
            this.theEnd = response.LastEvaluatedKey === undefined;
            this.loading = false;
            this.searching = false;
        });

    }

    keyNotAlreadyContained(key) {
        for (let i = 0; i < this.lastEvalArr.length; i++) {
            if (key.id === this.lastEvalArr[i].id && key.skey === this.lastEvalArr[i].skey)
                return false;
        }
        return true;
    }

    scrollHandler(event, message) {
        if (message === undefined || this.theEnd)
            return;

        const key = {
            id: message.id,
            skey: message.skey
        };

        const end = this.viewport.getRenderedRange().end;
        const total = this.viewport.getDataLength();
        if (end === total && !this.theEnd && !this.searching) {
            this.onSearch();
        }
    }
}
