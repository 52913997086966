import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '@app/services/dashboard.service';
import { DATE_TIME_FORMAT } from '@app/constants/text.constant';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NgbDateStruct, NgbCalendar, NgbTooltipModule, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { StateService } from '@app/services/state.service';
import { StateModel } from '@app/models/state.model';
import { AppCookieService } from '@app/services/app-cookie.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import { MemberService } from '@app/services/member.service';
import { PhonePipe } from '@app/shared/pipes/phone.pipe';
import { ToastrService } from 'ngx-toastr';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Observable, BehaviorSubject } from 'rxjs';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { CustomDateParserFormatter, CustomDateAdapter } from '@app/shared/custom-date-formatter-parser/custom-date-formatter-parser.module';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomDateAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    ]
})
export class DashboardComponent implements OnInit, OnDestroy {

    @ViewChild(CdkVirtualScrollViewport)
    viewport: CdkVirtualScrollViewport;

    theEnd = false;

    loading = true;
    searchModel = {};
    keyword = '';
    preference = null;
    isMobile: boolean;
    DATE_TIME_FORMAT = DATE_TIME_FORMAT;
    metrics = {};
    searching: boolean;
    removing = false;

    selectedMembers = [];

    private user: string;
    private pageNumber = 0;
    private start: Date = null;
    private end: Date = null;
    state: number;
    stateOptions = [];
    preferenceOptions = [];
    states: StateModel[];
    lastEvalArr = [];
    LastEvaluatedKey = {};
    stateId;

    registrationDate: NgbDateStruct;
    date: { year: number, month: number };

    members = {
        "Items": [],
        "Count": null,
        "ScannedCount": null
    };
    constructor(private service: DashboardService, private breakpointObserver: BreakpointObserver, private calendar: NgbCalendar, private router: Router, private cookie: AppCookieService,
        private stateService: StateService,
        private modalService: NgbModal, private memberService: MemberService, private toastr: ToastrService,
        private gaService: GoogleAnalyticsService) { }

    ngOnInit(): void {
        this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
            .subscribe(state => this.isMobile = state.matches);
        let search = {};

        this.getSearchModel(search);
        this.searchUsers(search);
        this.stateService.getAll().subscribe(states => {
            this.states = states;
            this.stateOptions = states.map(state => { return { "id": state.code, "name": state.description } });
        });
        if (this.cookie.getCookie("memberRemoved") === "true") {
            this.toastr.success("Member Removed", "");
            this.cookie.setCookie("memberRemoved", "false");
        }
        this.preferenceOptions = [{ "id": "E", "name": "Email" }, { "id": "S", "name": "SMS" }];
        this.getEmployerMetrics();
        this.loading = false;
    }

    getSearchModel(search) {
        this.stateId = this.cookie.getCookie("filter-stateId") === "" ? null : this.cookie.getCookie("filter-stateId");
        this.registrationDate = this.cookie.getCookie("filter-registrationDate") === "" ? null : this.getRegistrationDate(this.cookie.getCookie("filter-registrationDate"));
        this.preference = this.cookie.getCookie("filter-preference") === "" ? null : this.cookie.getCookie("filter-preference");
        this.keyword = this.cookie.getCookie("user-keyword") === "" ? null : this.cookie.getCookie("user-keyword");

        search["searchType"] = this.cookie.getCookie("filter-searchtype") === "" ? null : this.cookie.getCookie("filter-searchtype");
        search["keyword"] = this.cookie.getCookie("filter-keyword") === "" ? null : this.cookie.getCookie("filter-keyword");
        search["state"] = this.cookie.getCookie("filter-stateId") === "" ? null : this.cookie.getCookie("filter-stateId");
        search["registrationDate"] = this.cookie.getCookie("filter-registrationDate") === "" ? null : this.formatRegistrationDate();
        search["preference"] = this.cookie.getCookie("filter-preference") === "" ? null : this.cookie.getCookie("filter-preference");
    }

    getRegistrationDate(dateStr) {
        let parts = dateStr.split('-');
        let date = { "year": Number(parts[0]), "month": Number(parts[1]), "day": Number(parts[2]) };
        return date;
    }

    scrollHandler(event, member) {
        if (member === undefined || this.theEnd)
            return;

        const key = {
            id: member.id,
            skey: member.skey
        };

        const end = this.viewport.getRenderedRange().end;
        const total = this.viewport.getDataLength();

        if (end === total && !this.theEnd) {
            this.onSearch();
        }
    }

    activeMemberCount = "";

    getEmployerMetrics() {
        this.service.employerMetrics().subscribe(metrics => {
            metrics.Items.forEach(element => {
                if (element.skey === "ACTIVE_MEMBERS")
                    this.activeMemberCount = this.groupDigits(JSON.stringify(element.count));
            });
        });
    }

    groupDigits(val: string) {
        let parts = val.split("").reverse();
        let temp = [];
        for (let i = 0; i < parts.length; i++) {
            if (i % 3 === 0 && i !== 0)
                temp.push(",");
            temp.push(parts[i]);
        }
        let p = temp.reverse().join("");
        return p;
    }

    ngOnDestroy(): void {
    }


    onResetClick() {
        this.clearSearch();
        this.reinitializeVariables();
        location.reload();
    }

    clearSearch() {
        this.keyword = null;
        this.stateId = null;
        this.registrationDate = null;
        this.preference = null;

        this.cookie.setCookie('filter-searchtype', "");
        this.cookie.setCookie('filter-keyword', "");
        this.cookie.setCookie('user-keyword', "");
        this.cookie.setCookie('filter-stateId', "");
        this.cookie.setCookie('filter-registrationDate', "");
        this.cookie.setCookie('filter-preference', "");
    }

    reinitializeVariables() {
        this.lastEvalArr = [];
        this.LastEvaluatedKey = null;
        this.theEnd = false;
        this.members.Items = [];
        this.selectedMembers = [];
    }

    keywordSearch() {
        this.setSearchModel();
        location.reload();
    }

    setSearchModel() {
        if (this.keyword || this.stateId || this.registrationDate || this.preference) {
            this.searchModel["searchType"] = "KEYWORD";
        } else {
            this.searchModel["searchType"] = "";
        }
        if (this.keyword) {
            this.searchModel["keyword"] = this.keyword.toUpperCase();
        } else {
            this.searchModel["keyword"] = "";
        }
        if (this.stateId) {
            this.searchModel["state"] = this.stateId;
        } else {
            this.searchModel["state"] = "";
        }
        if (this.registrationDate) {
            this.searchModel["registrationDate"] = this.registrationDate;
        } else {
            this.searchModel["registrationDate"] = "";
        }
        if (this.preference) {
            this.searchModel["preference"] = this.preference;
        } else {
            this.searchModel["preference"] = "";
        }

        this.cookie.setCookie('user-keyword', this.keyword === null ? "" : this.keyword);
        this.cookie.setCookie('filter-keyword', this.searchModel["keyword"]);
        this.cookie.setCookie('filter-searchtype', this.searchModel["searchType"]);
        this.cookie.setCookie('filter-stateId', this.searchModel["state"]);
        this.cookie.setCookie('filter-registrationDate', this.searchModel["registrationDate"]);
        this.cookie.setCookie('filter-preference', this.searchModel["preference"]);
    }

    onSearch() {
        this.searchModel["lastEvaluatedKey"] = this.LastEvaluatedKey;
        this.searchUsers(this.searchModel);
    }

    formatRegistrationDate() {
        let month = this.registrationDate.month < 10 ? "0" + this.registrationDate.month : this.registrationDate.month;
        let day = this.registrationDate.day < 10 ? "0" + this.registrationDate.day : this.registrationDate.day;
        return this.registrationDate.year + "-" + month + "-" + day;
    }

    searchOnPrefChange() {
        this.reinitializeVariables();
        this.keywordSearch();
    }

    private searchUsers(search) {
        this.gaService.logEvent("Dashboard", "MemberSearch", "Search");
        this.searching = true;
        this.service.dashboardSearch(search).subscribe(response => {
            response.Items.forEach(member => {
                this.members.Items = [...this.members.Items, member];
            });

            if (response.LastEvaluatedKey && this.keyNotAlreadyContained(response.LastEvaluatedKey)) {
                this.lastEvalArr.push(response.LastEvaluatedKey);
                this.searchModel["lastEvaluatedKey"] = response.LastEvaluatedKey;
            }

            this.theEnd = response.LastEvaluatedKey === undefined;

            this.searching = false;
        });

    }

    keyNotAlreadyContained(key) {
        for (let i = 0; i < this.lastEvalArr.length; i++) {
            if (key.id === this.lastEvalArr[i].id && key.skey === this.lastEvalArr[i].skey)
                return false;
        }
        return true;

    }

    goToMemberDetail(member: any) {
        this.cookie.setCookie('member-id', member.id);
        this.cookie.setCookie('member-skey', member.skey);

        this.router.navigateByUrl('/member-detail', { state: member });
    }

    selectMember(member: any) {
        let index = -1;
        for (let i = 0; i < this.selectedMembers.length; i++) {
            if (this.selectedMembers[i].email === member.email) {
                index = i;
                break;
            }
        }
        if (index < 0) {
            this.selectedMembers.push(member);
        } else {
            this.selectedMembers.splice(index, 1);
        }
    }

    deleteMembers() {
        this.gaService.logEvent("Dashboard", "DeleteMembers", "Yes");
        this.removing = true;
        let word = this.getSelectedMembersWord();
        this.memberService.deleteMembers(this.selectedMembers).subscribe(response => {
            this.toastr.success(this.selectedMembers.length + " " + word + " removed.", "");
            this.removing = false;
            location.reload();
        });
    }

    getSelectedMembersWord() {
        return this.selectedMembers.length > 1 ? "members" : "member";
    }

    confirmDelete() {
        let word = this.getSelectedMembersWord();
        const confirmDialog = this.modalService.open(ConfirmDialogComponent, { centered: true });
        confirmDialog.componentInstance.pageTitle = "";
        confirmDialog.componentInstance.pageBody = "Are you sure you want to delete " + this.selectedMembers.length + " " + word + "?";
        confirmDialog.componentInstance.showCloseButton = false;
        confirmDialog.result.then(() => {
            this.deleteMembers();
        }, () => undefined);
    }

    goToMessageHistory() {
        this.router.navigateByUrl('/messages');
    }

    goToSendMessage() {
        this.memberService.changeMembersToMessage(JSON.stringify(this.selectedMembers));
        this.memberService.changeMessageType(this.preference);
        this.gaService.logEvent("Dashboard", "MessageMembers", "envelope-icon");
        this.router.navigateByUrl('/send-messages');
    }

}