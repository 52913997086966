<div class="container">
	<div class="row mb-2">
		<div class="col px-0 text-nowrap d-flex justify-content-center justify-content-sm-start">
			<button class="btn btn-outline-primary" (click)="confirmBackNav()">
				<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
				Back to Messages
			</button>
		</div>
		<div class="col text-center font-weight-bold text-nowrap">Bulk Message</div>
		<div class="col-sm"></div>
	</div>
</div>
<div class="container card bg-light">
	<form (ngSubmit)="onSubmit()" [formGroup]="messageForm">
		<div class="row mt-3">
			<div class="col-md-12 ">
				<div class="font-weight-bold">Type</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-auto pl-3 mb-2 mt-1">
				<label class="float-left btn btn-primary" [ngClass]="{'active':messageType=='E'}"> <input type="radio"
						name="messageType" id="Email" formControlName="messageType" value="E"
						(click)="confirmChangeMessageType('E')" checked> Email
				</label>
			</div>
			<div class="col-xs-auto mb-2 pl-1 mt-1">
				<label class="btn btn-primary" [ngClass]="{'active':messageType=='S'}"> <input type="radio"
						name="messageType" id="SMS" formControlName="messageType" value="S"
						(click)="confirmChangeMessageType('S')"> SMS
				</label>
			</div>
		</div>
		<ng-container *ngIf="messageType">
			<div class="row">
				<div class="col-md-12">
					<button class="btn btn-no-outline float-left" [promiseBtn]="searching" *ngIf="searching"></button>
					<div class="font-weight-bold">{{getSelectedMembersMessage()}}</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 mb-3">
					<ng-select formControlName="members" labelForId="memberSelect" (scrollToEnd)="getPageOfMembers()"
						[items]="listOfMembers" [multiple]="true" [clearable]="true" [searchable]="true"
						class="bg-white" (change)="checkAddOrRemove()" (focus)="onMemberFocus()"
						(search)="getNextSearch($event)">
					</ng-select>
					<div [hidden]="messageForm.controls.members.status==='VALID' || messageForm.controls.members.pristine"
						class="alert alert-danger mb-0">
						<div *ngIf="messageForm.controls.members.errors?.required">You must select at least one member
							before sending a message</div>
						<div *ngIf="messageForm.controls.members.errors?.maxlength">You may select up to {{memberLimit}}
							members.</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="font-weight-bold">Message</div>
				</div>
			</div>
			<div class="row" *ngIf="messageType==='E'">
				<div class="col-md-12 mb-1">
					<input class="form-control bg-white" id="search" placeholder="Subject"
						formControlName="messageSubject">
					<div [hidden]="messageForm.controls.messageSubject.status==='VALID' || messageForm.controls.messageSubject.pristine"
						class="alert alert-danger mb-0">
						<div *ngIf="messageForm.controls.messageSubject.errors?.required">Subject must contain at least
							one character</div>
						<div *ngIf="messageForm.controls.messageSubject.errors?.maxlength">Subject may only contain
							{{this.subjectMaxLength}} character.</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 mb-2">
					<div *ngIf="messageType === 'S'">
						<textarea formControlName="messageBody" placeholder="Body" class="form-control bg-white"
							id="search"></textarea>
						<div [hidden]="messageForm.controls.messageBody.status==='VALID' || messageForm.controls.messageBody.pristine"
							class="alert alert-danger mb-0">
							<div *ngIf="messageForm.controls.messageBody.errors?.required">SMS messages must contain at
								least one character</div>
							<div *ngIf="messageForm.controls.messageBody.errors?.maxlength">SMS messages may only
								contain {{smsMaxLength}} character</div>
						</div>
					</div>
					<div *ngIf="messageType === 'E'" id="quil-editor">
						<quill-editor formControlName="messageBody" format="html" maxLength="emailMaxLength"
							[modules]="mod"></quill-editor>
						<div [hidden]="messageForm.controls.messageBody.status==='VALID' || messageForm.controls.messageBody.pristine"
							class="alert alert-danger mb-0">
							<div *ngIf="messageForm.controls.messageBody.errors?.maxlength">Email messages may only
								contain {{emailMaxLength}} characters</div>
							<div *ngIf="messageForm.controls.messageBody.errors?.required">Email messages must contain
								at least one character</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row mb-5" *ngIf="messageType === 'S'">
				<div class="col-md-12">
					<div class="float-right small">{{messageForm.controls.messageBody.value === null ? 0 :
						messageForm.controls.messageBody.value.length}}/ {{messageType === "S" ? this.smsMaxLength
						:this.emailMaxLength }} characters</div>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-md-12">
					<button [disabled]="messageForm.status === 'INVALID'" [promiseBtn]="sending"
						class="btn btn-outline-primary float-right" type="submit">Send Message</button>
				</div>
			</div>
		</ng-container>
	</form>
</div>