<div class="row">
	<div class="col-md-12">
		<app-loading *ngIf="loading" class="loading-container" [bigSize]="true"> </app-loading>
	</div>
</div>
<div class="row">
	<div class="col-md-3"></div>
	<div class="col-md-6 stats-main-number font-weight-bold">Employer Dashboard</div>
	<div class="col-md-3"></div>
</div>
<div class="row">
	<div class="card-group w-100 pl-2 d-flex justify-content-center justify-content-sm-start">
		<div class="col-md-3 card metric-block mat-elevation-z4">
			<div class="card-body">
				<div class="stats-main-number mb-2">
					<h4 class="font-weight-bold mb-1">{{activeMemberCount}}</h4>
					<hr>
				</div>
				<div class="metric-title text-center">
					Talent <span class="nobr">Members</span>
				</div>
			</div>
		</div>
		<div class="col-md-6 d-none d-md-block"></div>
		<div class="col-md-3 card metric-block mat-elevation-z4 btn btn-outline-primary text-nowrap mr-0"
			(click)="goToMessageHistory()">
			<!-- 			<a> -->
			<!-- 				<div class="card-body" (click)="sendMessage()"> -->
			<!-- 					<div class="stats-main-number mb-2"> -->
			<!-- 						<i class="fa fa-envelope mr-2"></i> -->
			<!-- 						<hr> -->
			<!-- 					</div> -->
			<!-- 					<div class="metric-title text-center">Messages</div> -->
			<!-- 				</div> -->
			<!-- 			</a> -->
			<div class="card-body card-button-hover">
				<div class="stats-main-number mb-2">
					<h4 class="font-weight-bold mb-1 pl-2">
						<i class="fa fa-envelope mr-2"></i>
					</h4>
					<hr>
				</div>
				<div class="text-center">Messages</div>
			</div>
		</div>
	</div>
</div>
<div class="row">
	<div class="stats-main-number font-weight-bold">My Talent Community</div>
</div>
<div class="card">
	<div class="card-body">
		<div class="row align-items-end mt-n2">
			<div class="col-lg-3 mt-2">
				<label for="search" class="font-weight-bold">Name</label> <input class="form-control py-0" id="search"
					placeholder="Search" [(ngModel)]="keyword" (keyup.enter)="keywordSearch()">
			</div>
			<div class="col-lg-2 mt-2">
				<label for="state" class="font-weight-bold">State</label>
				<ng-select #ngSelect [items]="stateOptions" bindValue="id" bindLabel="name" class="py-0"
					[clearable]="true" [(ngModel)]="stateId" [searchable]="true"></ng-select>
			</div>
			<div class="col-lg-3 mt-2">
				<label for="schools" class="font-weight-bold">Registered on or after</label>
				<div class="input-group">
					<input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="registrationDate"
						ngbDatepicker #d="ngbDatepicker">
					<div class="input-group-append">
						<button class="btn btn-addition calendar" type="button" (click)="d.toggle()">
							<fa-icon [icon]="['fas', 'calendar']"></fa-icon>
						</button>
					</div>
				</div>
			</div>
			<div class="col-lg-2 mt-2">
				<label for="preference" class="font-weight-bold">Communication Preference</label>
				<ng-select #ngSelect [items]="preferenceOptions" bindValue="id" bindLabel="name" class="py-0"
					[clearable]="true" [(ngModel)]="preference" [searchable]="true" (change)="searchOnPrefChange()">
				</ng-select>
			</div>
			<div class="col-lg-2 d-flex mt-2 pl-0">
				<button class="btn btn-link btn-reset" (click)="onResetClick()">Reset</button>
				<button class="btn btn-primary text-nowrap" [promiseBtn]="searching"
					(click)="keywordSearch()">Search</button>
			</div>
		</div>
		<div *ngIf="selectedMembers.length > 0" class="row mt-2 mh-100">
			<div class="col-md-1 float-left">
				<button class="btn btn-outline-danger" (click)="confirmDelete()" [promiseBtn]="removing">
					<a><span class="color-red ml-2">
							<fa-icon [icon]="['fas', 'trash']"></fa-icon>
						</span></a>
				</button>
			</div>
			<div class="col-md-1 float-left">
				<button class="btn btn-outline-primary" (click)="goToSendMessage()">
					<a><span class=" color-green ml-2">
							<fa-icon [icon]="['fas', 'envelope']"></fa-icon>
						</span></a>
				</button>
			</div>
		</div>
	</div>
</div>

<div class="card table-card mt-3">
	<div class="table-responsive h-50">
		<ng-container>
			<ng-container>
				<cdk-virtual-scroll-viewport itemSize="10"
					(scrolledIndexChange)="scrollHandler($event,(members.Items[members.Items.length-1]))">
					<table class="table">
						<div class="d-flex p-2 border-bottom h6 text-left">
							<div class=" d-flex flex-row w-100 font-weight-bold">
								<div class="p-2 member-select-col"></div>
								<div class="p-2 text-secondary name-col">Name</div>
								<div class="p-2 text-secondary state-col">State</div>
								<div class="p-2 text-secondary email-col">Email</div>
								<div class="p-2 text-secondary phone-col">Phone Number</div>
								<div class="p-2 text-secondary registration-date-col">Registration Date</div>
							</div>
						</div>
						<tbody *ngIf="members.Items.length < 1">
							<div class="text-secondary overflow-hidden text-center">
								No members were found that match your
								search.
							</div>
						</tbody>
					</table>
					<li *cdkVirtualFor="let member of members.Items;" class="list-unstyled border-bottom  ">
						<div class="d-flex p-2 text-nowrap ">
							<div class=" d-flex flex-row w-100  text-secondary  text-left ">
								<div class="p-2 member-select-col "><input type="checkbox"
										(click)="selectMember(member)">
								</div>
								<div class="p-2 text-secondary name-col" (click)="goToMemberDetail(member)">
									<a class="d-inline-block text-truncate ellipsies-200"
										ngbTooltip="{{member.first_name +' '+ member.last_name |titlecase}}"
										container="body">{{member.first_name +' '+ member.last_name |titlecase}}</a>
								</div>
								<div class="p-2 text-secondary state-col">
									{{member.state}}
								</div>
								<div class="p-2 text-secondary  email-col">
									<span class="d-inline-block text-truncate ellipsies-300"
										ngbTooltip="{{member.email}}" container="body">{{member.email}}</span>
								</div>
								<div class="p-2 text-secondary   phone-col">
									{{member.phone | phone}}</div>
								<div class="p-2 text-secondary registration-date-col">
									{{member.registration_date | date: "MM/dd/yyyy"}}
								</div>
							</div>
						</div>
					</li>
					<div *ngIf="searching">
						<button class="btn btn-link btn-reset" [promiseBtn]="searching">Loading...</button>
					</div>
				</cdk-virtual-scroll-viewport>
			</ng-container>
		</ng-container>
	</div>
</div>