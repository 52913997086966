import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component( {
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html'
} )
export class ConfirmDialogComponent implements OnInit {

    @Input() pageTitle = '';
    @Input() pageBody = '';
    @Input() useYesNo = true;
    @Input() showCloseButton = true;

    confirmOptionText = "Confirm";
    cancelOptionText = "Cancel";

    constructor( public modal: NgbActiveModal ) {
    }

    ngOnInit() {
        if ( this.useYesNo ) {
            this.confirmOptionText = "Yes";
            this.cancelOptionText = "No";
        }
    }

    //  getAnalyticEvent(): string {
    //    return 'Delete - ' + this.pageTitle;
    //  }
}
