import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MemberService } from '@app/services/member.service';
import { MessageService } from '@app/services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import { QuillModule } from 'ngx-quill';
import { makeTitleCase } from '@app/components/utils/utility';
import { AppCookieService } from '@app/services/app-cookie.service';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-create-message',
    templateUrl: './create-message.component.html',
    styleUrls: ['./create-message.component.scss']
})
export class CreateMessageComponent implements OnInit, OnDestroy {

    listOfMembers = [];
    smsMaxLength = 160;
    emailMaxLength = 1000;
    subjectMaxLength = 100;
    debounceTimeout = 500; //ms
    memberLimit = environment.bulkMessageMemberCount;
    mod = {}
    ALL_MEMBERS = "All Members";
    LastEvaluatedKey = null;
    reachedLastMemeber = false;
    hasPerformedFocusSearch = false;
    searching = false;
    sending = false;

    membersPage = 1;
    membersToMessage = [];
    messageType = "";
    keyword = "";
    membersSubscription: Subscription;
    messageTypeSubscription: Subscription;
    memberSearchSubscription: Subscription;
    memberSubject = new Subject<any>();
    hasInitMember = false;
    initMember = null;

    formOpen = false;
    messageForm = this.fb.group({
        messageType: this.fb.control('', [Validators.required]),
        messageBody: this.fb.control('', [Validators.required]),
        messageSubject: this.fb.control(''),
        members: this.fb.control('', [Validators.required, Validators.maxLength(this.memberLimit)])
    });



    constructor(private router: Router, private fb: FormBuilder, private memberService: MemberService, private messageService: MessageService, private modalService: NgbModal, private cookie: AppCookieService,
        private gaService: GoogleAnalyticsService, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.setInitMember();

        this.membersSubscription = this.memberService.membersToMessage.subscribe(members => { if (members) this.membersToMessage = JSON.parse(members) });
        this.messageTypeSubscription = this.memberService.messageType.subscribe(messageType => { if (messageType) this.messageType = messageType });
        this.memberSearchSubscription = this.memberSubject.pipe(debounceTime(this.debounceTimeout), distinctUntilChanged()).subscribe(name => this.onMemberSearch(name));

        this.setMessageType(this.messageType, false);

        this.mod = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean'],                                         // remove formatting button

                ['link', 'image']                         // link and image, video
            ]
        };
    }

    private setInitMember() {
        this.initMember = this.messageService.getMemberToMessage();
        this.hasInitMember = this.initMember && (this.initMember.phone || this.initMember.email);
        if (this.hasInitMember) {
            this.membersToMessage.push(this.initMember);
            let label = makeTitleCase(this.initMember.first_name) + " " + makeTitleCase(this.initMember.last_name);
            this.initMember["label"] = label;
            this.listOfMembers = [this.initMember];
            this.messageForm.controls.members.setValue([this.initMember]);
            if (!this.initMember.unsubscribe_email && (this.initMember.preference === "E" || this.initMember.preference === "B")) {
                this.messageType = "E";
                this.messageForm.controls.messageType.setValue("E");
            } else {
                this.messageType = "S";
                this.messageForm.controls.messageType.setValue("S");
            }
        }
    }

    getNextSearch(event) {
        this.listOfMembers = [];
        this.memberSubject.next(event.term);
    }

    ngOnDestroy() {
        this.membersSubscription.unsubscribe();
        this.messageTypeSubscription.unsubscribe();
        this.memberSearchSubscription.unsubscribe();
    }

    defaultMemberSearchModel = {
        "searchType": "MESSAGE",
        "messageType": "",
        "keyword": null,
        "lastEvaluatedKey": null
    };

    defaultCursor = {
        "before": "",
        "after": "",
        "hasBefore": "",
        "hasAfter": ""
    };

    onMemberFocus() {
        this.keyword = null;
        if (!this.hasPerformedFocusSearch) {
            this.hasPerformedFocusSearch = true;
            this.getPageOfMembers();
        }
    }

    onMemberSearch(term) {
        this.keyword = term;
        this.LastEvaluatedKey = null;
        this.listOfMembers = [];
        this.reachedLastMemeber = false;
        this.getPageOfMembers();
    }

    getPageOfMembers(): any {
        if (!this.reachedLastMemeber) {
            let search = this.defaultMemberSearchModel;
            search.messageType = this.messageType;
            search.lastEvaluatedKey = this.LastEvaluatedKey;
            search.keyword = this.keyword ? this.keyword.toUpperCase : null;

            this.searching = true;
            this.allowFields(false);
            this.memberService.searchMember(search).subscribe(response => {
                if (response.LastEvaluatedKey !== undefined) {
                    this.LastEvaluatedKey = response.LastEvaluatedKey;
                } else {
                    this.LastEvaluatedKey = null;
                    this.reachedLastMemeber = true;
                }
                this.getMemberList(response, search.keyword);

                this.searching = false;
                this.allowFields(true);
            });
        }
    }

    getMemberList(response, keyword) {
        if (!this.listOfMembers.includes(this.ALL_MEMBERS) && keyword === null) {
            this.listOfMembers.push(this.ALL_MEMBERS);
        }
        response.Items.forEach(mem => {
            if (!this.initMember || (this.initMember.skey != mem.skey)) {
                let label = makeTitleCase(mem.first_name) + " " + makeTitleCase(mem.last_name);
                mem["label"] = label;
                this.listOfMembers = [...this.listOfMembers, mem];
            }
        });
    }

    allowFields(allow: boolean) {
        if (allow) {
            this.messageForm.controls.messageType.enable();
            this.messageForm.controls.messageBody.enable();
            this.messageForm.controls.messageSubject.enable();
        } else {
            this.messageForm.controls.messageType.disable();
            this.messageForm.controls.messageBody.disable();
            this.messageForm.controls.messageSubject.disable();
        }
    }

    getSelectedMembersMessage() {
        let word = "Members";
        if (this.messageForm.controls.members.value.length < 2)
            word = "Member";
        return this.messageForm.controls.members.value.length > 0 ? this.messageForm.controls.members.value.length + " " + word + "  Selected " : "Search Members:";
    }

    page = 0;
    lastEvalArr = [];
    collectionSize = 0;
    reinitializeVariables() {
        this.page = 0;
        this.collectionSize = 0;
        this.lastEvalArr = [];
        this.LastEvaluatedKey = {};
    }

    dashboard() {
        this.router.navigateByUrl('/dashboard');
    }

    setMessageType(type: string, removeInitMember: boolean) {
        this.messageType = type;

        this.listOfMembers = [];
        this.LastEvaluatedKey = null;
        this.reachedLastMemeber = false;
        this.hasPerformedFocusSearch = false;

        if (this.formOpen || this.membersToMessage === []) {

            this.messageForm.controls.messageSubject.setValue("");
            this.messageForm.controls.messageBody.setValue("");
            this.messageForm.controls.members.setValue([]);

            if (type === 'S') {
                this.messageForm.controls.messageSubject.clearValidators();
                this.messageForm.controls.messageBody.clearValidators();
                this.messageForm.controls.messageBody.setValidators([Validators.required, Validators.maxLength(this.smsMaxLength)]);
                this.messageForm.controls.members.setValidators([Validators.required, Validators.maxLength(this.memberLimit)]);
                this.gaService.logEvent("SendMessage", "ChangeMessageType", "SMS");
            } else {
                this.messageForm.controls.messageSubject.setValidators([Validators.required, Validators.maxLength(this.subjectMaxLength)]);
                this.messageForm.controls.messageBody.clearValidators();
                this.messageForm.controls.messageBody.setValidators([Validators.required, Validators.maxLength(this.emailMaxLength)]);
                this.messageForm.controls.members.setValidators([Validators.required, Validators.maxLength(this.memberLimit)]);
                this.gaService.logEvent("SendMessage", "ChangeMessageType", "Email");
            }

            this.messageForm.controls.messageSubject.updateValueAndValidity();
            this.messageForm.controls.messageBody.updateValueAndValidity();

            this.messageForm.controls.messageSubject.markAsPristine();
            this.messageForm.controls.messageBody.markAsPristine();
            this.messageForm.controls.members.markAsPristine();

            this.messageForm.controls.messageSubject.markAsUntouched();
            this.messageForm.controls.messageBody.markAsUntouched();
            this.messageForm.controls.members.markAsUntouched();

        } else {
            this.membersToMessage.forEach(mem => {
                this.listOfMembers = [...this.listOfMembers, makeTitleCase(mem.first_name) + " " + makeTitleCase(mem.last_name)];
            });
            this.formOpen = true;
        }
        if (removeInitMember)
            this.initMember = null;

    }

    checkAddOrRemove() {
        if (this.messageForm.controls.members.value.includes(this.ALL_MEMBERS)) {
            if (this.listOfMembers.length > this.messageForm.controls.members.value.length) {
                //add all members case
                this.listOfMembers.splice(this.listOfMembers.indexOf(this.ALL_MEMBERS), 1);
                this.messageForm.controls.members.setValue(this.listOfMembers);

                this.listOfMembers = [];
                this.listOfMembers = [...this.listOfMembers, this.ALL_MEMBERS];
                this.messageForm.controls.members.value.forEach(mem => {
                    this.listOfMembers = [...this.listOfMembers, mem];
                });
            } else {
                // remove all members case
                this.messageForm.controls.members.setValue([]);
            }
        }
    }

    onSubmit() {
        console.log("Submitted");
        let action = "Send " + (this.messageType === 'S' ? 'SMS' : 'Email');
        this.gaService.logEvent("SendMessage", action, "Send Message");

        let members = this.messageForm.controls.members.value;

        let message = {
            messageType: this.messageForm.controls.messageType.value,
            members: members,
            subject: this.messageForm.controls.messageSubject.value ? this.messageForm.controls.messageSubject.value : null,
            body: this.messageForm.controls.messageBody.value,
            url: environment.url,
        };

        console.log("send message: " + JSON.stringify(message));
        this.sending = true;
        this.messageService.sendBulkMessage(message).subscribe(response => {
            if (!response.error) {
                this.toastr.success("Your message has been sent!");
            } else {
                this.toastr.error("Please wait a few minutes and try again.");
                console.log("Send Message Error: " + JSON.stringify(response.error.message));
            }
            this.sending = false;
        }
            ,
            error => {
                this.toastr.error("Please wait a few minutes and try again.");
            }
        );
    }

    isInfoEntered() {

        let hasSubject = this.messageForm.controls.messageSubject.value !== null && this.messageForm.controls.messageSubject.value.length > 0;
        let hasBody = this.messageForm.controls.messageBody.value !== null && this.messageForm.controls.messageBody.value.length > 0;
        let hasMembers = this.messageForm.controls.members.value !== null && this.messageForm.controls.members.value.length > 0;

        return hasSubject || hasBody || hasMembers;
    }

    confirmChangeMessageType(type: string) {

        if (this.formOpen && this.isInfoEntered() && this.isMessageTypeChanged(type)) {
            const confirmDialog = this.modalService.open(ConfirmDialogComponent, { centered: true });
            confirmDialog.componentInstance.pageTitle = "";
            confirmDialog.componentInstance.pageBody = "Changing the message type will clear the message and selected members. Do you wish to continue?";
            confirmDialog.componentInstance.showCloseButton = false;
            confirmDialog.result.then(() => {
                this.setMessageType(type, true);
            }, () => undefined);
        } else {
            if (this.isMessageTypeChanged(type))
                this.setMessageType(type, true);
        }
        this.formOpen = true;
    }

    isMessageTypeChanged(type: string) {
        return this.messageType !== type;
    }

    confirmBackNav() {

        if (this.isInfoEntered()) {
            const confirmDialog = this.modalService.open(ConfirmDialogComponent, { centered: true });
            confirmDialog.componentInstance.pageTitle = "";
            confirmDialog.componentInstance.pageBody = "Leaving this page will clear the form and will not send a message. Do you wish to continue?";
            confirmDialog.componentInstance.showCloseButton = false;
            confirmDialog.result.then(() => {
                this.router.navigateByUrl('/messages');
            }, () => undefined);
        } else {
            this.router.navigateByUrl('/messages');
        }
    }

}
