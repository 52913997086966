export function makeTitleCase( input: string ) {
    if(input === undefined)
        return;
    if ( input.length < 1 )
        return "";
    if ( input.length === 1 ) {
        return input.toUpperCase();
    } else {
        return input[0].toUpperCase() + input.substr( 1 ).toLowerCase();
    }
}