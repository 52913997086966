<div class="container">
	<div class="modal-header">
		<div class="modal-title h4" id="modal-title">{{pageTitle}}</div>
		<button *ngIf="showCloseButton" type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>{{pageBody}}</p>
	</div>
	<div class="modal-footer d-flex flex-nowrap">
		<div class="row ">
			<div class="col">
				<button class="btn btn-outline-primary no-wrap" (click)="modal.dismiss('Cancel')">{{cancelOptionText}}</button>
			</div>
			<div class="col">
				<button class="btn btn-outline-danger no-wrap" (click)="modal.close(true)">{{confirmOptionText}}</button>
			</div>
		</div>
	</div>
</div>